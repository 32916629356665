import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '@shopify/polaris/build/esm/styles.css'
import Router from './router/Router'
import { setShopData } from "./redux/commonSlice";
import './App.css';
import { generateTokenAPI } from "./helpers/axios";
import { Provider, NavigationMenu } from '@shopify/app-bridge-react';
import { config, shop_name } from "./config";




function App() {

  let dispatch = useDispatch()
  let shop_data = useSelector((state) => state.commonReducer.shop_data)
  let onboarding_status = shop_data.onboarding_status
  let [onBoardingStatus, setOnBoardingStatus] = useState()
  let generateToken = async () => {
    let res = await generateTokenAPI('POST', '/generate-token', { shop: window.location.hostname === 'localhost' ? 'dev-preorder.myshopify.com' : shop_name });
    let shop_data = res.data.data.shop_data;
    let token_data = res.data.data.token;
    localStorage.setItem('token', token_data)
    dispatch(setShopData(shop_data))
  }
  console.log("{process.env.REACT_APP_PREFIX}=============>", process.env.REACT_APP_BASE_URL)
  useEffect(() => {
    generateToken()
  }, [])

  useEffect(() => {
    setOnBoardingStatus(parseInt(onboarding_status))
  }, [onboarding_status])

  if (window.location.hostname === 'localhost' || (window.location.href.indexOf("ngrok") > 1)) {
    return (
      <Router onBoardingStatus={onBoardingStatus} />
    )
  } else {
    return (
      <Provider config={config}>
        {onBoardingStatus && onBoardingStatus === 1 && <NavigationMenu
          navigationLinks={[
            {
              label: 'Dashboard',
              destination: `${process.env.REACT_APP_BASE_URL}/dashboard`,
            },
            {
              label: 'Single products',
              destination: `${process.env.REACT_APP_BASE_URL}/editor/single-product`,
            },
            {
              label: 'Tag',
              destination: `${process.env.REACT_APP_BASE_URL}/editor/tags`,
            },
            {
              label: 'Setting',
              destination: `${process.env.REACT_APP_BASE_URL}/editor/setting`,
            }
          ]}
          matcher={(link, location) => link.destination === location.pathname}
        />}
        <Router onBoardingStatus={onBoardingStatus} />
      </Provider>
    );
  }
}

export default App;
