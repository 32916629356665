let api_key, apiEndpoint, tokenEndpoint, shop_name;
// let url = window.location.href
let config = {};
// var hostname = window.location.hostname;
let preOrderCurrentScriptUrl = document.currentScript.src;
let preOrderDomainName = preOrderCurrentScriptUrl.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
// const urlSearchParams = new URLSearchParams(window.location.search);
// const params = Object.fromEntries(urlSearchParams.entries());

if (preOrderDomainName === "localhost" || preOrderDomainName === "localhost:3001" || preOrderDomainName === "localhost:3000") {
    apiEndpoint = `http://localhost:4000`;
    tokenEndpoint = 'http://localhost:4000/node/admin_api';
}
else if (preOrderDomainName === "preorderbat.com") {
    shop_name = window.Shopify.shop
    let hostName = btoa(`${shop_name}/admin`)
    config = { apiKey: '933a1b1a24ba481b304691cfae08f690', host: hostName }
    apiEndpoint = `https://preorderbat.com`;
    tokenEndpoint = 'https://preorderbat.com/node/admin_api';
    api_key = "933a1b1a24ba481b304691cfae08f690";
}

module.exports = {
    api_key: api_key,
    apiEndpoint: apiEndpoint,
    tokenEndpoint: tokenEndpoint,
    config: config,
    shop_name: shop_name
};
