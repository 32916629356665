import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  shop_data: {}
}

export const commonSlice = createSlice({
  name: 'userName',
  initialState,
  reducers: {
    setShopData: (state, action) => {
      state.shop_data = action.payload
    },
    setInitialShopData: (state, action) => {
      state.shop_data = {}
    }
  }
})

// Action creators are generated for each case reducer function
export const { setShopData, setInitialShopData } = commonSlice.actions

export default commonSlice.reducer