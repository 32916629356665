import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  single_product_data: {}
}

export const commonSlice = createSlice({
  name: 'Single Product',
  initialState,
  reducers: {
    setSingeProductData: (state, action) => {
      state.single_product_data = action.payload
    },
    setInitialSingeProductData: (state, action) => {
      state.shop_data = {}
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSingeProductData, setInitialSingeProductData } = commonSlice.actions

export default commonSlice.reducer