import commonReducer from "./commonSlice";
import TagReducer from "./TagSlice"
import singleProductReducer from './singleProductSlice'

const rootReducer = {
    commonReducer,
    TagReducer,
    singleProductReducer
}

export default rootReducer;