import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/style.css'
import App from './App';
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider i18n={enTranslations}>
    <Frame>
      <Provider store={store}>
        <App />
      </Provider>
    </Frame>
  </AppProvider>
);

