import React, { lazy, Suspense } from 'react'
import { BrowserRouter as AppRouter, Route, Routes } from "react-router-dom";
import { Spinner } from '@shopify/polaris';
import Header from '../pages/Header'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const WelcomePage = lazy(() => import('../pages/WelcomePage'))
const SingleProduct = lazy(() => import('../pages/SingleProduct'))
const SingleProductSetting = lazy(() => import('../pages/SingleProductSetting'))
const StylesSetting = lazy(() => import('../pages/StylesSetting'))
const Tags = lazy(() => import('../pages/Tags'))
const AddTag = lazy(() => import('../pages/AddTag'))
const AllProducts = lazy(() => import("../pages/AllProducts"))
const InstallUninstall = lazy(() => import("../pages/InstallUninstall"))
const OnBoarding = lazy(() => import("../pages/OnBoarding"))
const AdvancedSetting = lazy(() => import("../pages/AdvancedSetting"))
const Setting = lazy(() => import("../pages/Setting"))
const EditSingleProduct = lazy(() => import("../pages/EditSingleProduct"))
const PricingPlan = lazy(() => import('../pages/PricingPlan'))
const PageNotFound = lazy(() => import('../pages/404/PageNotFound'))

const Router = ({ onBoardingStatus }) => {
  return (
    <AppRouter basename={process.env.REACT_APP_BASE_URL}>
      <Suspense fallback={<div className='h-100 d-flex align-items-center justify-content-center'>
        <Spinner />
      </div>}>
        <Header />
        <Routes>
          <Route
            path={"/"}
            exact={true}
            element={<WelcomePage />}
          // element={onBoardingStatus && onBoardingStatus === 1 ? <Dashboard /> : <WelcomePage/>}
          />
          <Route
            path={"/dashboard"}
            exact={true}
            element={<Dashboard />}
          />
          <Route
            path={"/single-product"}
            exact={true}
            element={<SingleProduct />}
          />
          <Route
            path={"/single-product/setting"}
            exact={true}
            element={<SingleProductSetting />}
          />
          <Route
            path={"/tags"}
            exact={true}
            element={<Tags />}
          />
          <Route
            path={"/addTag"}
            exact={true}
            element={<AddTag />}
          />
          <Route
            path={"/tags/edit/:id"}
            exact={true}
            element={<AddTag />}
          />
          <Route
            path={"/all-products"}
            exact={true}
            element={<AllProducts />}
          />
          <Route
            path={"/install-uninstall"}
            exact={true}
            element={<InstallUninstall />}
          />
          <Route
            path={"/on-boarding"}
            exact={true}
            element={<OnBoarding />}
          />
          <Route
            path={"/advanced-setting"}
            exact={true}
            element={<AdvancedSetting />}
          />
          <Route
            path={"/setting"}
            exact={true}
            element={<Setting />}
          />
          <Route
            path={"/style-setting"}
            exact={true}
            element={<StylesSetting />}
          />
          <Route
            path={"/edit-single-product/:product_id"}
            exact={true}
            element={<EditSingleProduct />}
          />
          <Route
            path={"/edit-single-product"}
            exact={true}
            element={<EditSingleProduct />}
          />
          <Route
            path={"/pricingplan"}
            exact={true}
            element={<PricingPlan />}
          />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </AppRouter>
  )
}

export default Router
